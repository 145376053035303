import React from 'react';
import Section from '../common/section'

import tumLogo from "../../images/logos/tum.jpg";

const experiences = [
  {
    name: "University of California, Berkeley",
    title: "Visiting Student",
    period: "08/2024 - 05/2025",
    location: "Berkeley, United States",
    description: "Taking courses on Compiler Construction & Programming Languages and Database Systems.",
    imageUrl: tumLogo 
  },
  {
    name: "Technical University of Munich",
    title: "M.Sc. Computer Science",
    period: "04/2024 - 08/2025",
    location: "Munich, Germany",
    description: "Majoring in Databases, with minors in Distributed Systems and High Performance Computing. Other relevant coursework: Operations Research, Algorithms for Uncertainty Quantification.",
    imageUrl: tumLogo 
  },
  {
    name: "Technical University of Munich",
    title: "B.Sc. Computer Science",
    period: "03/2021 - 03/2024",
    location: "Munich, Germany",
    description: "Taught students how to program iOS mobile apps using Swift as a Teaching iOS tutor. Developed an algorithm in x86 Assembly that computes large Fibonacci numbers using matrix multiplication optimization.",
    imageUrl: tumLogo 
  }
];

const Education = () => {
  return <Section sectionTitle="Education" data={experiences} styleOverrides={{ minHeight: '170px' }} />;
};

export default Education;
