import React from 'react';
import Section from '../common/section'

import microsoftLogo from "../../images/logos/microsoft.jpg";
import snowflakeLogo from "../../images/logos/snowflake.jpg";
import munichReLogo from "../../images/logos/munichre.jpg";
import netlightLogo from "../../images/logos/netlight.jpg";
import deloitteLogo from "../../images/logos/deloitte.jpg";
import amazoneLogo from "../../images/logos/amazone.jpg";
import ororatechLogo from "../../images/logos/ororatech.jpg";
import faktor30Logo from "../../images/logos/faktor30.jpg";


const experiences = [
      {
      name: "Microsoft",
      type: "Internship",
      title: "CSA & Software Engineering",
      period: "02/2024 - 07/2024",
      location: "Munich, Germany",
      description: "Contributed to the Microsoft ISV team to implement frameworks for third-party VS Code extension development. Implemented a VS Code extension for IaaC development. \nContributed to the Cloud Security team to advise mid to large-scale companies on cloud security architecture to improve problem identification skills. Initialized a project to create  a chatbot that notifies CSAs (Cloud Solution Architects) about consumption changes of their customers, minimizing manual work efforts by ~70%. Onboarded two interns and rolled out chatbot beta version to our team of 80 employees.",
      imageUrl: microsoftLogo
    },
    {
      name: "Snowflake",
      type: "Internship",
      title: "Software Engineer",
      period: "11/2023 - 01/2024",
      location: "Warsaw, Poland",
      description: "Contributed to the Database Connectors team that provides native integration of database systems into the Snowflake. Implemented a Connector for an SQL database type that supports snapshots and continuous replications into the Snowflake. Owned the development of the Connector and shipped the product ahead of time.",
      imageUrl: snowflakeLogo
    },
    {
      name: "Munich Re",
      type: "Internship",
      title: "Software Engineer",
      period: "08/2023 - 10/2023",
      location: "Munich, Germany",
      description: "Munich Re is a world leader in reinsurance, using quantitative models to assess and manage complex risks. Contributed to the Cloud Computing team to develop the internal cloud infrastructure. Implemented a Python library for asynchronous data pipelines.",
      imageUrl: munichReLogo
    },
    {
      name: "Netlight",
      type: "Internship",
      title: "Software Engineer",
      period: "10/2022 - 02/2023",
      location: "Munich, Germany",
      description: "Contributed to the learning and development team as an TU Munich iPraktikum student. The learning and development team develops personal growth solutions for Netlight employees. I contributed to the development of a Swift App that offers video courses and learning progress sharing by an integrated social media.",
      imageUrl: netlightLogo 
    },
    {
      name: "Deloitte",
      type: "Internship",
      title: "Software Engineer",
      period: "11/2022 - 01/2023",
      location: "Munich, Germany",
      description: "Contributed to the Data Analytics team to develop solutions for big data use cases in audit workflows. Owned the development of an automation tool for automatic data preparation and conversion in audit workflows and data type conversions. Adopting the automation tool has streamlined the audit workflow of our team of 35 employees.",
      imageUrl: deloitteLogo 
    },
    {
      name: "Amazone",
      type: "Internship",
      title: "Software Engineer",
      period: "03/2022 - 04/2022",
      location: "Osnabrueck, Germany",
      description: "Contributed to the Research and Development team. The Research and Development team creates cutting-edge agricultural solutions. I developed a machine learning model for the optimization of fertilizer machine precision. Furthermore, I developed an application that tracks and analyzes GPS coordinates.",
      imageUrl: amazoneLogo
    },
    {
      name: "Orora Technologies",
      type: "Internship",
      title: "Innovation Consultant",
      period: "10/2021 - 03/2022",
      location: "Munich, Germany",
      description: "Contributed to the Research and Development team within the Manage and More scholarship program. The scholarship encompasses a consulting project that teaches scholars design thinking. I investigated the attractiveness of real-time satellite data for the re-insurance market.",
      imageUrl: ororatechLogo
    },
    {
      name: "Faktor30",
      type: "Part-Time & Full-Time",
      title: "Co-Founder",
      period: "03/2019 - 11/2021",
      location: "Reinbek, Germany",
      description: "Co-founded a B2B Software Engineering company during high school. Employed and led 6 employees, developed the software, and integrated the products with the customers. Sold software engineering services to customers including the Hamburger Sparkasse and Henssler Food Concepts AG. ",
      imageUrl: faktor30Logo
    }
];

const Experience = () => {
  return <Section sectionTitle="Work Experience" data={experiences} />;
};

export default Experience;
